<template>
  <a-upload-dragger
    v-model:fileList="fileList"
    name="excel_file"
    :multiple="false"
    :action="$store.state.import_excel_upload_addr.uploadAddr.addr"
    @change="handleChange"
    :disabled="uploadDisable"
    :headers="uploadHeader"
  >
    <p class="ant-upload-drag-icon">
      <inbox-outlined></inbox-outlined>
    </p>
    <p class="ant-upload-text">{{ uploadText }}</p>
    <p class="ant-upload-hint">
      {{uploadHint}}
    </p>
    <a-divider style="height: 2px; background-color: #7cb305" />
    <p class="ant-upload-hint">
      {{uploadResult}}
    </p>
  </a-upload-dragger>
  
</template>
<script>
// 在获取文件上传的url后，拖入文件执行上传
import { InboxOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';
import {useStore} from 'vuex';
export default defineComponent({
  components: {
    InboxOutlined,
  },
  setup() {
    const store = useStore()
    // const properties = getCurrentInstance()
    // const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const uploadResult = ref('')
    const uploadHeader = ref({Authorization: "JWT " + store.state.user_login_auth.user["token"]})
    const uploadText = ref('请选择数据导入类型！')
    const uploadHint = ref('需要在上面的选择工具中选择上传的是哪一种文件。')
    const uploadDisable = ref(true)
    const handleChange = info => {
      if (store.state.import_excel_upload_addr.uploadAddr.addr !== '') {
          
          if (info.file.status === 'done') {
              message.success("数据导入执行成功！")
              uploadResult.value = info.file.response;
          }
      } else {
          message.error(`${info.file.name} 上传地址错误！`);
      }
    };
    watch (() => store.state.import_excel_upload_addr.uploadAddr, function() {
      if (store.state.import_excel_upload_addr.uploadAddr !== '') {
          uploadDisable.value = false;
          uploadText.value = "已经成功选择导入数据类型";
          uploadHint.value = "选中的方法为："+store.state.import_excel_upload_addr.uploadAddr.method+"您现在可以拖入对应文件或者点击这个区域上传数据";
      }
    });

    return {
      handleChange,
      fileList: ref([]),
      uploadDisable,
      uploadText,
      uploadHint,
      uploadResult,
      uploadHeader
    };
  },
});
</script>