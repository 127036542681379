<template>
<div>
  <!-- 获取上传的链接（类型） -->
  <SelectImportType :routeSelectedImportMethod="routeSelectedImportMethod"/>
  <!-- 执行上传，导入数据 -->
  <UploadExcel 
  />
  </div>
</template>

<script>
import UploadExcel from '@/components/import_excel/UploadExcel.vue'
import SelectImportType from '@/components/import_excel/SelectImportType.vue'
import {defineComponent} from 'vue'
export default defineComponent({
  props:['routeSelectedImportMethod'],
    components: {
        UploadExcel,
        SelectImportType
    }
});
</script>

<style>

</style>