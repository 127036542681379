<template>
  <a-layout>
    <dashboardSider siderMenu="ImportExcel" v-if="$store.state.user_login_auth.user['authenticated'] === true" />
    <a-layout>
      <DashBoardHeader />
      <DashBoardContent v-if="$store.state.user_login_auth.user['authenticated'] === true"/>
      <DashBoardFooter />
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent } from 'vue';
import dashboardSider from '@/components/dashboard_sider/dashboardSider.vue';
import DashBoardHeader from '@/layouts/dash_board/DashBoardHeader.vue';
import DashBoardContent from '@/layouts/dash_board/DashBoardContent.vue';
import DashBoardFooter from '@/layouts/dash_board/DashBoardFooter.vue';
export default defineComponent({
  components: {
    dashboardSider,
    DashBoardHeader,
    DashBoardContent,
    DashBoardFooter,
  },
});
</script>
<style>
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout-sub-header-background {
  background: #141414;
}
</style>