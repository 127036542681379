<template>
  <a-select v-model:value="category" style="width: 240px"
  @change="handleCategoryChange"
  >
    <a-select-option v-for="c in categoryData" :key="c" :value="c">
      {{ c }}
    </a-select-option>
  </a-select>
  <a-select v-model:value="certainMethod" style="width: 360px"
  @Change="handleMethodChange"
  >
    <a-select-option v-for="m in methods" :key="m" :value="m">
      {{ m }}
    </a-select-option>
  </a-select>
</template>
<script>
const categoryData = ['请选择'];
const certainMethodData = {
  请选择: ['请选择'],
};
const urlData = {'请选择': ''};
import { defineComponent, reactive, toRefs, computed, watch, onMounted, getCurrentInstance } from 'vue';
import {message} from 'ant-design-vue'
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
// 选择import的文件类型，也就是excel文件上传的地址
export default defineComponent({
  props: ['routeSelectedImportMethod'],
  setup(props) {
    const category = categoryData[0]
    const state = reactive({
      category,
      categoryData,
      certainMethodData,
      urlData,
      certainMethod: certainMethodData[category][0],
    });
    const store = useStore()
    // 获取全局属性的方法
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    onMounted(() => {
      request({
        // 获取全局属性的方法
        url: baseUrl + '/v1/import_excel/',
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        state.urlData = {...state.urlData, ...response.data}
        for (var k in response.data) {
          state.certainMethodData[k.split('_')[1]] = []
          if (!state.categoryData.includes(k.split('_')[1])) {
              state.categoryData.push(k.split('_')[1])
          }
        }
        for (var kk in response.data) {
          state.certainMethodData[kk.split('_')[1]].push(kk)
        }
      }).catch(() => {
        message.error('获取方法列表失败！')
      }
      );
    if (props.routeSelectedImportMethod) {
      store.commit({
        type: "import_excel_upload_addr/saveData",
        payload: {'addr': state.urlData[props.routeSelectedImportMethod], 'method': props.routeSelectedImportMethod}
      })
    }
    })
    const methods = computed(() => {
      return certainMethodData[state.category];
    });
    watch(
      () => state.category,
      val => {
        state.certainMethod = state.certainMethodData[val][0];
      },
    );
    const handleMethodChange = value => {
      store.commit({
        type: "import_excel_upload_addr/saveData",
        payload: {'addr': state.urlData[value], 'method': value}
      })
    }
    const handleCategoryChange = value => {
      store.commit({
        type: "import_excel_upload_addr/saveData",
        payload: {'addr':state.urlData[state.certainMethodData[value][0]],
        'method': state.certainMethodData[value][0]
        }
      })
    }
    return { ...toRefs(state), methods, handleMethodChange, handleCategoryChange, baseUrl };
  },
});
</script>